import type { PartialSource } from './sources'

export interface SourceValue {
  id: string
  dimension: string
  source: PartialSource
  sourceValue: string
  mappedValue: string | null
  lastUsedAt: string | null
  firstUsedAt: string | null
}

export type SourceValueWithDimensionTitle = SourceValue & {
  dimensionTitle: string | null
}

export enum SourceValueDimension {
  SUBORGANIZATION = 'SUBORGANIZATION',
  DEPARTMENT = 'DEPARTMENT',
  GROUP_1 = 'GROUP_1',
  GROUP_2 = 'GROUP_2',
  GROUP_3 = 'GROUP_3',
  LOCATION_OFFICE = 'LOCATION_OFFICE',
  LOCATION_REMOTE = 'LOCATION_REMOTE',
  LOCATION_1 = 'LOCATION_1',
  LOCATION_2 = 'LOCATION_2',
  JOB_TITLE = 'JOB_TITLE',
  NATIONALITY = 'NATIONALITY',
  CAREER_STAGE = 'CAREER_STAGE',
  SUB_CAREER_STAGE = 'SUB_CAREER_STAGE',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  HIRING_SOURCE = 'HIRING_SOURCE',
  HIRING_TYPE = 'HIRING_TYPE',
  MANAGEMENT_CATEGORY = 'MANAGEMENT_CATEGORY',
  REMOTE_STATUS = 'REMOTE_STATUS',
  JOB_STATUS = 'JOB_STATUS',
  JOB_LOCATION_1 = 'JOB_LOCATION_1',
  PAYROLL_CATEGORY = 'PAYROLL_CATEGORY',
  SALARY_CATEGORY = 'SALARY_CATEGORY',
  EMPLOYMENT_CATEGORY = 'EMPLOYMENT_CATEGORY',
  PROFESSIONAL_CATEGORY = 'PROFESSIONAL_CATEGORY',
  DEPARTURE_REASON_CATEGORY = 'DEPARTURE_REASON_CATEGORY',
  DEPARTURE_REASON_TYPE = 'DEPARTURE_REASON_TYPE',
  MOBILITY_CATEGORY = 'MOBILITY_CATEGORY',
  ABSENTEEISM_CATEGORY = 'ABSENTEEISM_CATEGORY',
  ABSENTEEISM_TYPE = 'ABSENTEEISM_TYPE',
  CUSTOM_CATEGORY_TYPE_EGAPRO = 'CUSTOM_CATEGORY_TYPE_EGAPRO',
  RECRUITMENT_STAGE = 'RECRUITMENT_STAGE',
  RECRUITMENT_REJECTION_CATEGORY = 'RECRUITMENT_REJECTION_CATEGORY'
}

export enum NormalizedFieldEntry {
  NATIONALITY__TYPE = 'NATIONALITY__TYPE',
  HIRING_SOURCE__TYPE = 'HIRING_SOURCE__TYPE',
  MANAGEMENT_CATEGORY__IS_MANAGER = 'MANAGEMENT_CATEGORY__IS_MANAGER',
  REMOTE_STATUS__TYPE = 'REMOTE_STATUS__TYPE',
  JOB_STATUS__TYPE = 'JOB_STATUS__TYPE',
  RECRUITMENT_STAGE__TYPE = 'RECRUITMENT_STAGE__TYPE',
  RECRUITMENT_STAGE__IS_REJECTED = 'RECRUITMENT_STAGE__IS_REJECTED',
  RECRUITMENT_REJECTION_CATEGORY__TYPE = 'RECRUITMENT_REJECTION_CATEGORY__TYPE',
  HIRING_TYPE__TYPE = 'HIRING_TYPE__TYPE',
  PAYROLL_CATEGORY__TYPE = 'PAYROLL_CATEGORY__TYPE',
  PAYROLL_CATEGORY__IS_IN_EGAPRO_INDEX = 'PAYROLL_CATEGORY__IS_IN_EGAPRO_INDEX',
  SALARY_CATEGORY__TYPE = 'SALARY_CATEGORY__TYPE',
  EMPLOYMENT_CATEGORY__TYPE = 'EMPLOYMENT_CATEGORY__TYPE',
  PROFESSIONAL_CATEGORY__TYPE_IN_EGAPRO_INDEX = 'PROFESSIONAL_CATEGORY__TYPE_IN_EGAPRO_INDEX',
  DEPARTURE_REASON_CATEGORY__TYPE = 'DEPARTURE_REASON_CATEGORY__TYPE',
  DEPARTURE_REASON_CATEGORY__IS_REGRETTED = 'DEPARTURE_REASON_CATEGORY__IS_REGRETTED',
  DEPARTURE_REASON_TYPE__IS_REGRETTED = 'DEPARTURE_REASON_TYPE__IS_REGRETTED',
  MOBILITY_CATEGORY__TYPE = 'MOBILITY_CATEGORY__TYPE',
  ABSENTEEISM_CATEGORY__TYPE = 'ABSENTEEISM_CATEGORY__TYPE',
  ABSENTEEISM_CATEGORY__IS_PLANNED_IN_EGAPRO_INDEX = 'ABSENTEEISM_CATEGORY__IS_PLANNED_IN_EGAPRO_INDEX',
  ABSENTEEISM_CATEGORY__MATERNITY_TYPE_IN_EGAPRO_INDEX = 'ABSENTEEISM_CATEGORY__MATERNITY_TYPE_IN_EGAPRO_INDEX',
  ABSENTEEISM_CATEGORY__IS_REAL = 'ABSENTEEISM_CATEGORY__IS_REAL',
  ABSENTEEISM_CATEGORY__IS_PLANNED = 'ABSENTEEISM_CATEGORY__IS_PLANNED',
  ABSENTEEISM_TYPE__IS_PLANNED = 'ABSENTEEISM_TYPE__IS_PLANNED'
}

export interface NormalizedField {
  id: string
  field: NormalizedFieldEntry
  normalizedValue: string | null
}

export type SourceValueWithNormalizedFields = SourceValueWithDimensionTitle & {
  normalizedFields: NormalizedField[]
}

export interface EmptyNormalizedField {
  id?: null
  field: NormalizedFieldEntry
  normalizedValue: null
}

export interface AIGeneratedNormalizedField {
  field: NormalizedFieldEntry
  normalizedValue: string
}

export type NormalizedFieldsOptions = {
  [normalizedFieldEntry in NormalizedFieldEntry]: string[]
}

export type NormalizedFieldsByDimension = {
  [dimensionKey: string]: NormalizedFieldEntry[]
}

export type SourceValueWithMissingNormalizedFields = SourceValue & {
  normalizedFields: (
    | NormalizedField
    | EmptyNormalizedField
    | AIGeneratedNormalizedField
  )[]
}

export type NormalizationEditorSorting = [
  sortingKey: 'sourceValue' | 'source' | NormalizedFieldEntry,
  sortingValue: 1 | -1
]

export type MappingEditorSorting = [
  sortingKey: 'sourceValue' | 'source' | 'mappedValue',
  sortingValue: 1 | -1
]

export interface SourceValueDimensionOption {
  label: string
  dimensionKey: string
}

export type SourceValuesByDimension = {
  [dimensionKey: string]: SourceValueWithDimensionTitle[]
}

export interface NormalizationAnalytics {
  normalizedValuesTotal: number
  valuesTotal: number
}

export type NormalizationAnalyticsByFieldEntry = {
  [normalizedFieldEntry in NormalizedFieldEntry]?: NormalizationAnalytics
}
